import * as React from 'react';
import Link from '../../global/link/link';
import Styles from './footer.module.scss';

export default props => {
  return (
    <footer className={`${Styles.footer} container mt-5 pb-5`}>
        <div className="row">
            <div className="col-12 col-md-4 mb-4 mb-md-0">
                <address className="m-0">
                    <h1>Christoph Hauke</h1>
                    3Gen Worx<br></br>
                    Prinz-Georg-Str. 91<br></br>
                    40479 Düsseldorf
                </address>
            </div>
            <div className="col-12 col-md-4 mb-4 mb-md-0">
                <h1>Kontakt</h1>
                <ul className="list-unstyled m-0">
                    <li>
                        <a className="unstyled" href="tel:+492114497227">Tel: +49 211 44 97 227</a>
                    </li>
                    <li>
                        <a className="unstyled" href="mailto:info@christophhauke.de">Mail: info@christophhauke.de</a>
                    </li>
                </ul>
            </div>
            <div className="col-12 col-md-4 mb-4 mb-md-0">
                <h1>Rechtliches</h1>
                <ul className="list-unstyled m-0">
                    <li>
                        <Link to="/impressum" className="unstyled">Impressum</Link>
                    </li>
                    <li>
                        <Link to="/datenschutz" className="unstyled">Datenschutz</Link>
                    </li>
                </ul>
            </div>
        </div>
            <div className="row">
                <div className="col-12">
                    <hr className="my-md-4"></hr>
                    <a className={`unstyled ${Styles.expoLink}`} href="https://www.exportarts.io" rel="noopener nofollow">
                        Made with
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                            <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"/>
                        </svg>
                        by exportarts.io
                    </a>
                </div>
            </div>
    </footer>
  );
}
